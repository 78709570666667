export default (minValue, maxValue, initialValue = 0) => {
    const fluidValue = ref(0);
    const { getCssVar } = useCssVariables();

    const clientDeviceStore = useClientDeviceStore();
    const width = computed(() => clientDeviceStore.windowWidth);

    const minScreen = ref(initialValue);
    const maxScreen = ref(initialValue);

    const setValue = () => {
        if (width.value < minScreen.value) {
            fluidValue.value = minValue;
            return;
        }

        if (width.value > maxScreen.value) {
            fluidValue.value = maxValue;
            return;
        }

        fluidValue.value = (
            (maxValue - minValue)
            / (maxScreen.value - minScreen.value)
        ) * (width.value - minScreen.value) + minValue;
    };

    onMounted(() => {
        minScreen.value = parseInt(getCssVar('--fluid-min-screen'), 10);
        maxScreen.value = parseInt(getCssVar('--fluid-med-screen'), 10);
        setValue();
    });

    watch(() => width.value, () => {
        setValue();
    });

    return computed(() => fluidValue.value);
};
