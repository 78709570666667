<template>
  <header
    ref="headerRef"
    class="app-header"
    :class="{ 'is-sticky': headerIsSticky, 'flyout-open': flyoutIsOpen }"
  >
    <div class="app-header__nav">
      <div
        v-if="navigationItems"
        class="app-header__nav-items"
      >
        <nav class="app-header__nav-element">
          <SlotLink class="app-header__logo" url="../">
            <AtomImage
              v-if="headerLogo && headerLogo.length === 1"
              disable-lazy
              :data="headerLogo[0]"
              :is-svg="true"
            />
          </SlotLink>

          <div class="app-header__links-wrapper only-desktop">
            <ul class="app-header__links">
              <li
                v-for="(item, index) in navigationItems"
                :key="item._uid"
                class="app-header__item"
              >
                <SlotLink
                  v-if="item.component === 'AppLink'"
                  :data="item"
                >
                  {{ item.label }}
                </SlotLink>
                <button
                  v-else
                  type="button"
                  class="app-header__item-flyout"
                  :class="{
                    'is-active': activeMenuIndex === index,
                  }"
                  @click="flyoutStore.openFlyout(index, activeMenuIndex)"
                  @keypress="flyoutStore.openFlyout(index, activeMenuIndex)"
                >
                  {{ item.label }}
                </button>
              </li>
            </ul>
          </div>
          <div class="app-header__icons">
            <ul class="app-header__links">
              <li
                v-for="item in iconItems"
                :key="item._uid"
                :class="{
                  'hide-on-mobile': item.hide_on_mobile,
                }"
              >
                <SlotLink
                  v-if="
                    item.icon
                      && item.icon !== 'user'
                      && item.icon !== 'logout'
                      && item.icon !== 'cart'
                      && item.icon !== 'bookmark'
                  "
                  class="app-header__icon"
                  :url="item.link.cached_url"
                >
                  <AppIcon :icon="item.icon" :is-raw="true" />
                </SlotLink>

                <SlotLink
                  v-if="
                    item.icon
                      && item.icon === 'bookmark'
                      && !item.link.cached_url.includes('gast')
                      && loggedInUser
                  "
                  class="app-header__icon"
                  :url="item.link.cached_url"
                >
                  <AppIcon :icon="item.icon" :is-raw="true" />
                </SlotLink>

                <SlotLink
                  v-if="
                    item.icon
                      && item.icon === 'bookmark'
                      && item.link.cached_url.includes('gast')
                      && !loggedInUser
                  "
                  class="app-header__icon"
                  :url="item.link.cached_url"
                >
                  <AppIcon :icon="item.icon" :is-raw="true" />
                </SlotLink>

                <SlotLink
                  v-if="item.icon && item.icon === 'cart'"
                  class="app-header__icon"
                  :url="item.link.cached_url"
                >
                  <AtomCartIcon />
                </SlotLink>

                <SlotLink
                  v-if="item.icon && item.icon === 'user'"
                  class="app-header__icon"
                  :url="
                    loggedInUser
                      ? item.link.cached_url
                      : 'login'
                  "
                >
                  <AppIcon :icon="item.icon" :is-raw="true" />
                </SlotLink>

                <button
                  v-if="
                    item.icon
                      && item.icon === 'logout'
                      && loggedInUser
                  "
                  type="button"
                  class="app-header__icon"
                  @click="logoutUser()"
                >
                  <AppIcon :icon="item.icon" :is-raw="true" />
                </button>
              </li>

              <li>
                <SlotLink
                  v-if="iconContact
                    && iconContact.length !== 0
                    && headerIsSticky"
                  class="app-header__icon not-mobile"
                  :url="iconContact[0].link.cached_url"
                >
                  <AppIcon
                    class="app-header__contact-icon-sticky"
                    :icon="iconContact[0].icon"
                    :is-raw="true"
                  />
                </SlotLink>
              </li>
              <li
                class="app-header__burger only-tablet"
                @click="flyoutStore.openFlyout()"
                @keypress="flyoutStore.openFlyout()"
              >
                <button type="button" class="app-header__icon">
                  <span />
                  <span />
                  <span />
                </button>
              </li>
            </ul>
          </div>
        </nav>

        <div
          v-if="!clientDeviceStore.isMobile && !headerIsSticky"
          class="app-header__contact"
        >
          <SlotLink
            v-if="iconContact && iconContact.length !== 0"
            :url="iconContact[0].link.cached_url"
          >
            <AppIcon
              class="app-header__contact-icon"
              :icon="iconContact[0].icon"
              :is-raw="true"
            />
          </SlotLink>
        </div>
      </div>
    </div>
    <transition name="fade-in">
      <AppNavMegaFlyout
        v-if="flyoutStore.getMenuState && !clientDeviceStore.isTablet"
        :data="flyoutData"
        :flyout-store="flyoutStore"
        :active-menu-index="activeMenuIndex"
        :active-level2-index="activeLevel2Index"
        :active-level3-index="activeLevel3Index"
      />
      <AppNavMegaFlyoutMobile
        v-else-if="
          flyoutStore.getMenuState && clientDeviceStore.isTablet
        "
        :data="flyoutData"
      />
    </transition>
  </header>
</template>

<script setup>
/*
    Setup
*/
const clientDeviceStore = useClientDeviceStore();
const route = useRoute();

/*
    Check for sticky
*/
const headerMargin = useFluidValue(20, 60, 20);
const headerRef = ref(null);
const headerIsSticky = ref(false);
const { throttledExecute } = useThrottledExecute(2);
const scrollInitListener = ref(null);

watch(() => clientDeviceStore.scrollPosition, (newValue) => {
    throttledExecute(() => {
        headerIsSticky.value = newValue > headerMargin.value;
        if (scrollInitListener.value) {
            window.addEventListener(scrollInitListener.value);
        }
    });
});

/*
    Get settings store
*/
const headerLogo = computed(() => getStoryblokSetting('navigation', 'header_logo'));
const navigationItems = computed(() => getStoryblokSetting('navigation', 'header_navigation'));
const iconItems = computed(() => getStoryblokSetting('navigation', 'header_icon_links'));
const iconContact = computed(
    () => getStoryblokSetting('navigation', 'header_icon_contact'),
);

/*
    Get Flyoutstore / Flyout handling
*/
const flyoutStore = useFlyoutStore();
flyoutStore.setLevels();
const flyoutData = computed(() => flyoutStore.getFlyoutData);
const flyoutIsOpen = computed(() => flyoutStore.getMenuState);
const activeMenuIndex = computed(() => flyoutStore.getLevel1Index);
const activeLevel2Index = computed(() => flyoutStore.getLevel2Index);
const activeLevel3Index = computed(() => flyoutStore.getLevel3Index);

watch(
    () => route.path,
    () => {
        // close menu on route change
        if (flyoutStore.getMenuState) {
            flyoutStore.closeFlyout();
        }
    },
);

/*
  Get user to check if user is logged in
*/
const { logout } = useStrapiAuth();
const loggedInUser = useStrapiUser();
const cartStore = useCartStore();

const logoutUser = () => {
    logout();
    cartStore.revokeCart();
    navigateTo('/');
};
</script>

<style lang="scss">
/*
    Note: The commented out transition properties are for the sticky header.
    They are commented out because it's probably a bit too much animation.
    Especially on page changes it is a bit distracting.
    If you want to enable them, remove the comments.
*/
$transitionDuration: 0.10s;
.app-header {
    @include z-index('header');
    @include fluid('margin-top', 20px, 60px);

    --headerNegativeMargin: calc(0px - (var(--headerMargin) + var(--headerHeight)));

    position: sticky;
    top: 0;
    margin-bottom: var(--headerNegativeMargin);

    &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0px;
        width: var(--grid-offset);
        border-radius: 6px;
        background: $C_WHITE;
        content: '';
        transform: translateX(100%);
        // transition: transform 0.25s ease-in-out;
    }

    &.is-sticky {
        border-bottom: 2px solid;
        border-bottom-color: $C_GREEN_DARK_OPACITY_10;
        background: $C_WHITE;
        // transition: background-color $transitionDuration ease-in-out $transitionDuration;
        &:before {
            border-radius: 0;
            transform: translateX(0);
        }
    }
}

.app-header__nav {
    width: 100%;
    max-width: calc(var(--grid-width) - var(--page-padding) * 2);
    height: var(--headerHeight);
    margin: 0 auto;

    // transition: height $transitionDuration ease-in-out, max-width $transitionDuration ease-in-out;

    &.is-sticky {
        max-width: 100%;
    }
}

.app-header__nav-items {
    display: flex;
    height: 100%;
    align-items: center;
}

.app-header__nav-element {
    z-index: 1;
    display: flex;

    height: 100%;
    flex-grow: 0.001;
    align-items: center;
    padding-left: 40px;
    border: 2px solid $C_GREEN_DARK_OPACITY_10;
    background-color: $C_WHITE;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 90px;
    border-top-left-radius: 12px;
    border-top-right-radius: 90px;
    // transition: flex-grow 0.25s ease-in-out, border-radius 0.25s ease-in-out, border-color 0.15s ease-in-out 0.2s;

    .is-sticky & {
        flex-grow: 1;
        border-color: $C_WHITE;
        border-radius: 0;
        // transition: flex-grow 0.25s ease-in-out, border-radius 0.25s ease-in-out, border-color 0.05s ease-in-out;
    }

    @include mobile {
        padding-left: 20px;

        .is-sticky & {
            .app-header__icons {
                margin-left: auto;
            }
        }
    }
}

.app-header__logo {
    @include fluid('width', 56px, 89px);
    @include fluid('height', 30px, 53px);
    @include fluid('margin-right', 21px, 50px);

    img {
        height: 100%;
    }
}

.app-header__links-wrapper {
    position: relative;
    margin-right: 55px;

    .app-header__item {
        @include typo-size('a');
        @include typo-font('bold');

        position: relative;
        margin-right: 40px;
        color: $C_GREEN_DARK;
        transition: color 0.2s;
        white-space: nowrap;

        &:hover {
            color: $C_SECONDARY;
        }

        .router-link-exact-active {
            &:after {
                position: absolute;
                bottom: -7px;
                left: 0;
                width: 30px;
                border: 1px solid currentColor;
                border-radius: 100px;
                content: '';
                opacity: 1;
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .app-header__item-flyout {
        position: relative;

        &::after {
            position: absolute;
            bottom: -7px;
            left: 0;
            width: 30px;
            border: 1px solid currentColor;
            border-radius: 100px;
            content: '';
            opacity: 0;
        }

        &.is-active {
            color: $C_PRIMARY;

            &::after {
                opacity: 1;
            }
        }
    }
}

.app-header__links {
    display: flex;
    align-items: center;
}

.app-header__icons {
    display: flex;
    height: 100%;

    &.only-tablet {
        display: none;

        @include tablet {
            display: flex;
        }
    }

    li {
        display: flex;
        align-items: center;
    }
    .app-header__icon {
        margin-right: 40px;

        @include mobile {
            margin-right: 20px;
        }
    }

    .app-icon {
        width: 32px;
        height: 32px;
        transition: transform 0.1s ease-in-out;

        &:hover {
            transform: scale(1.08);
        }

        @include mobile {
            width: 28px;
            height: 28px;
        }
    }

    .app-header__burger {
        cursor: pointer;

        span {
            position: relative;
            display: block;
            width: 21px;
            height: 2px;
            margin-bottom: 4px;
            background-color: $C_GREEN_DARK;
        }

        &.only-tablet {
            display: none;

            @include tablet {
                display: flex;
            }
        }

        @include mobile {
            display: flex;
            width: 76px;
            height: 100%;
            align-items: center;
            justify-content: center;
            border: 3px solid $C_WHITE;
            border-radius: 100px;
            background-color: $C_GREY_LIGHT_GREEN_CYAN;

            .is-sticky & {
                width: 40px;
                background: none;
            }

            .app-header__icon {
                margin-right: 0;
            }
        }
    }

    @include mobile {
        .hide-on-mobile {
            display: none;
        }
    }
}

.app-header__contact {
    right: 0;
    display: flex;
    width: 142px;
    height: 100%;
    align-items: center;
    justify-content: right;
    background-color: #6f7c05;

    border-bottom-right-radius: 90px;
    border-top-right-radius: 90px;
    transform: translateX(-60px);

    .is-sticky & {
        opacity: 0;
    }

    .app-header__contact-icon {
        width: 32px;
        height: 32px;
        margin-right: 30px;
        transition: transform 0.1s ease-in-out;

        &:hover {
            transform: scale(1.08);
        }
    }

    @include mobile {
        display: none;
    }
}
.app-header__logout {
    position: absolute;
    top: 0;
    right: 0;
    min-width: unset;
    margin-top: 22px;
}

.app-header__contact-icon-sticky {
    path {
        fill: $C_GREEN_DARK;
    }
}

.only-desktop {
    display: block;
    @include tablet {
        display: none;
    }
}

.only-tablet {
    display: none;

    @include tablet {
        display: block;
    }
}

.only-mobile {
    display: none;
    @include mobile {
        display: block;
    }
}

.not-mobile {
    display: block;
    @include mobile {
        display: none;
    }
}
</style>
