export default () => {
    const setCssVar = (variableName, value) => document.documentElement.style
        .setProperty(variableName, value);

    const getCssVar = (variableName) => getComputedStyle(document.documentElement)
        .getPropertyValue(variableName);

    return {
        getCssVar,
        setCssVar,
    };
};
